html, body {
    padding: 0;
    margin: 0;
    overflow: hidden;
}

.App {
    font-family: "Roboto", sans-serif;

    align-items: center;

    display: flex;
    flex-direction: column;

    min-height: 100vh;
    width: 100vw;
}
